import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;

  ${({ arrowsColor, arrowsBgColor }) => `
        & .slick-arrow:before {
            color: ${arrowsColor};
            background-color: ${arrowsBgColor};
            border-radius: 100%;
        }
    `}

  & .slick-slide {
    cursor: pointer;
  }
`;
