import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './SlickSlider.styles';
import Slider from 'react-slick';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));

const SlickSlider = ({ children, items, ...settings }) => {
  const { currentTubIndex, currentCategory, setProductImageIndex } = useContext(
    FunnelContext
  );

  const beforeChange = (_, newIndex) => {
    let _newIndex = newIndex;
    if ((newIndex === 0 && currentCategory === 'onetime') || newIndex >= 1) {
      _newIndex = _newIndex + 1;
    }
    setProductImageIndex(_newIndex);
  };

  const _settings = {
    beforeChange,
    accessibility: settings?.accessibility || true,
    adaptiveHeight: settings?.adaptiveHeight || false,
    arrows: settings?.arrows || true,
    autoplay: settings?.autoplay || false,
    autoplaySpeed: settings?.autoplaySpeed || 3000,
    centerMode: settings?.centerMode || false,
    centerPadding: `${settings?.centerPadding || 50}px`,
    className: settings?.className || ``,
    dots: settings?.dots || true,
    draggable: settings?.draggable || true,
    easing: settings?.easing || 'linear',
    fade: settings?.fade || false,
    focusOnSelect: settings?.focusOnSelect || false,
    infinite: settings?.infinite || true,
    initialSlide: settings?.initialSlide || 0,
    lazyLoad: settings?.lazyLoad || true,
    pauseOnDotsHover: settings?.pauseOnDotsHover || true,
    pauseOnFocus: settings?.pauseOnFocus || true,
    pauseOnHover: settings?.pauseOnHover || true,
    rows: settings?.rows || 1,
    rtl: settings?.rtl || false,
    slide: 'div',
    slidesPerRow: settings?.slidesPerRow || 1,
    slidesToScroll: settings?.slidesToScroll || 1,
    slidesToShow: settings?.slidesToShow || 1,
    speed: settings?.speed || 500,
    swipe: settings?.swipe || true,
    swipeToSlide: settings?.swipeToSlide || true,
    touchMove: settings?.touchMove || false,
    useTransform: settings?.useTransform || false,
    variableWidth: settings?.variableWidth,
    vertical: settings?.vertical || false
  };

  const arrowsColor = settings?.arrowsColor;
  const arrowsBgColor = settings?.arrowsBgColor;

  const sliderItems = [];

  items.map(({ showIn }, itemIndex) => {
    return showIn.map(s => {
      if (
        s.currentTubIndex === currentTubIndex &&
        s.currentCategory === currentCategory
      ) {
        sliderItems.push(children[itemIndex]);
      }
      return null;
    });
  });

  return (
    <>
      <Helmet>
        <link
          key="slick"
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          key="slick-theme"
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <S.Container arrowsColor={arrowsColor} arrowsBgColor={arrowsBgColor}>
        <Slider {..._settings}>{sliderItems}</Slider>
      </S.Container>
    </>
  );
};

export default SlickSlider;
